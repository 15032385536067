import React from "react"
import Layout from "../components/layout"
import WorkSection from "../components/workSection"
import styles from "./work.module.scss"
import WorkData from "../../content/work-content.json"

const Work = () => {
  return (
    <Layout>
      <h2 className={styles.title}>My recent works...</h2>
      {WorkData.map((data, index) => {
        return <WorkSection work={data} key={index} />
      })}
    </Layout>
  )
}

export default Work
