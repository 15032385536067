import React from "react"
import styles from "./workSection.module.scss"

const WorkSection = ({ work }) => {
  return (
    <div className={styles.main}>
      <section className={styles.sectionContainer}>
        <div>
          <h1>{work.title}</h1>
          <p>{work.description}</p>
          <h5>Year: {work.date}</h5>
          <a href={work.link} target="_blank" rel="noopener noreferrer">
            <button className={styles.buttonPrimary}>{work.cta} &rarr;</button>
          </a>
        </div>
        <div>
          <img src={work.image} alt="" />
        </div>
      </section>
    </div>
  )
}

export default WorkSection
